<template>
    <nav class="menu-items">
        <ul class="menu-items__list d-sm-flex">
            <li v-for="(item, index) of items" class="menu-items__item d-sm-none" :key="index">
                <router-link :to="{name: item.route, params: item.params}"
                             @click.native="onMenuItemClick(item)"
                             class="menu-items__link">{{ item.name }}
                </router-link>
            </li>
            <li class="menu-items__item d-none d-sm-block">
                {{ texts.makeup }}
                <div class="dropdown-menu">
                    <ul class="dropdown-menu__ul">
                        <li v-for="(item, index) of items" :key="index">
                            <!--<img class="dropdown-menu__icon" :src="item.icon"/>-->
                            <router-link :to="{name: item.route, params: item.params}"
                                         @click.native="onMenuItemClick(item)"
                                         class="menu-items__link">{{ item.name }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="menu-items__item">
                <router-link :to="{ name: 'novelties'}"
                             @click.native="onMenuItemClick({ name: 'novelties'})"
                             class="menu-items__link">{{ buttons.new }}
                </router-link>
            </li>
            <li class="menu-items__item">
                <router-link :to=" {name: 'About'} "
                             @click.native="onMenuItemClick({name: 'About'})"
                             class="menu-items__link">{{ buttons.about }}
                </router-link>
            </li>
            <li v-if="languageCode !== 'en'" class="menu-items__item">
                <router-link :to=" {name: 'Shops'} "
                             @click.native="onMenuItemClick({name: 'Shops'})"
                             class="menu-items__link">{{ buttons.buy }}
                </router-link>
            </li>
            <li class="menu-items__item">
                <button class="menu-items__link footer-only"
                        @click="isModalOpened = true">
                    {{ buttons.openForm }}
                </button>
            </li>
        </ul>
        <modal-form
            :is-open="isModalOpened"
            @close="isModalOpened = false"></modal-form>
    </nav>
</template>

<style lang="scss">
.header {
    .footer-only {
        display: none;
    }
}

.menu-items {
    font-size: 21px;
    line-height: 150%;
    color: $black;
    z-index: 2;
    //position: relative;



    &__link {
        color: $blue-1 !important;
        transition: color 0.3s ease;

        &:hover,
        &:focus,
        &:active {
            color: $primary500 !important;
        }
    }

    .dropdown-menu {
        display: none;
        opacity: 0;

    }

    &__list {
        @include up($sm) {
            justify-content: center;
        }
    }

    &__item {
        position: relative;
        z-index: 2;
        cursor: pointer;

        &:not(:last-child) {
            @include up($sm) {
                //margin-right: 80px;
            }

            &:after {
                position: absolute;
                content: "";
                top: 0;
                right: -40px;
                height: 100%;
                border-right: 1px solid $black;
            }
        }

        @include up($sm) {

            &:hover {
                .dropdown-menu {
                    display: block;
                    opacity: 1;
                    width: 100%;
                    position: absolute;
                    text-align: center;
                    background-color: transparent;

                    & li {
                        &:first-child {
                            margin-top: 20px;
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>

<script>
import ModalForm from "./modal-form";
export default {
    name: "menu-items",
    components: {ModalForm},
    data: () => {
        return {
            isModalOpened: false
        }
    },
    computed: {
        pageLinks() {
            return this.$store.state.common.menuLinks[0];
        },

        languageCode() {
            return this.$store.state.common.languageCode;
        },

        texts() {
            return {
                news: this.$store.state.common?.buttons?.MENU_NEWS,
                makeup: this.$store.state.common?.commontext?.MAKEUP
            }
        },

        items() {
            const items = this.$store.state.common?.sections?.map(el => ({
                name: el.NAME,
                route: "Direction",
                params: {
                    dir: el.CODE
                },
                icon: el.UF_ICON
            }));

            return items;
        },

        buttons() {
            return {
                new: this.$store.state.common?.buttons?.MAIN_NEW,
                about: this.$store.state.common?.buttons?.MENU_ABOUT,
                buy: this.$store.state.common?.buttons?.MENU_BUY,
                openForm: this.$store.state.common?.buttons?.FORM_OPEN
            }
        }

    },
    methods: {
        onMenuItemClick(item) {
            this.$emit("select", item);
            // alternatively -> this.$store.state.isBurgerMenuShown = false;
        },
        onFosClick() {

        }

    }

}
</script>


