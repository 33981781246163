<template>
	<header class="header d-flex align-center">
		<v-container class="d-flex align-center px-sm-0">
			<router-link to="/" class="header__logo d-none d-sm-flex align-center">
				<img class="d-block" src="~@/assets/logo-lg.svg">
			</router-link>


			<div class="header__items header__items--logo d-flex ma-auto align-center">
				<menu-items class="d-none d-sm-flex"/>
			</div>


			<div class="header__burger d-sm-none" @click="toggleBurgerMenu">
				<div class="header__burger-icon"
					 :class="{'header__burger-icon--closed':isBurgerMenuShown}">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>

			<burger-menu v-if="isBurgerMenuShown" @select="onMenuItemSelected" class="d-sm-none"/>
			<div class="header__right d-flex align-center">

				<div class="header__langs d-none d-sm-flex mr-5">
					<span class="header__langs-current item">{{ currentLanguageName }}</span>
					<div class="header__langs-selector">
						<div class="header__langs-items">
							<template v-for="(item,index) of langs">
						<span @click="onLang(item)" :class="{active:item.code===currentLangCode}">
							{{ item.name }}
						</span>
								<span v-if="index<langs.length-1" class="dot"></span>
							</template>
						</div>
					</div>
				</div>
				<site-search v-if="withSearch" class="d-none d-sm-block"/>
			</div>
		</v-container>
	</header>
</template>

<style lang="scss">
.header {
	padding: 46px 10px 24px;
	position: fixed;
	width: 100%;
	left: 0;
	z-index: 3;
	@include up($sm) {
		padding: 15px 0;
		background: $bg;
	}

	&__langs {
		position: relative;
		z-index: 10;
		cursor: pointer;

		&-current {
			position: relative;
			z-index: 1;
			margin-right: 10px;

			&:hover {
				border-bottom-color: transparent !important;
			}
		}

		&-selector {
			display: none;
			position: absolute;
			right: 0;
			top: 20px;
			padding-top: 10px;
		}

		&-items {
			padding: 20px 10px;
			background-color: $bg;
			//border-top: 1px solid $black;
			display: flex;
			flex-direction: column;
			align-items: center;

			span {
				text-align: right;
				cursor: pointer;
				color: $black;
				@include transition();

				&.active {
					//color: $default-text-color;
				}

				&:hover {
					opacity: 0.6;
				}
			}
		}

		&:hover {
			.header__langs-selector {
				display: block;
			}
		}
	}

	& .container {
		@include up($xl) {
			max-width: 1730px;
		}
	}

	& .select-custom {
		padding: unset;
		background-color: unset;
		box-shadow: unset;
		border-radius: 20px;
		font-size: 18px;
		width: unset;
		height: unset;
	}

	& .menu-items {
		@include up($sm) {
			margin-left: 150px;
		}
		@include up($lg) {
			margin-left: unset;
		}

		&__list {
			@include up($sm) {
				width: 500px;
				justify-content: space-between;
			}
		}

		&__item {
			position: static;
			cursor: pointer;

			&:not(:last-child) {
				padding-bottom: 10px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.16);

				@include up($sm) {
					//margin-right: 80px;
					padding-bottom: unset;
					border-bottom: unset;
				}
			}


			@include up($sm) {
				&:hover {
					@keyframes ani {
						0% {
							opacity: 0;
						}
						100% {
							opacity: 1;
						}
					}

					.dropdown-menu {
						opacity: 0;
						animation: ani 1s forwards;
						padding: 100px 0 80px;
						display: block;
						position: absolute;
						width: calc(100% - 100px);
						margin: 0 50px;
						left: 0;
						text-align: center;
						background-color: $bg;
						font-size: 36px;
						line-height: 43px;
						box-shadow: 0px 20px 25px -10px rgb(0 0 0 / 10%);
						@include up($md) {
							width: calc(100% - 136px);
							margin: 0 68px;
						}
						@include up($lg) {
							padding: 120px 0 100px;
						}

						&::before {
							position: absolute;
							content: "";
							width: 430px;
							height: 410px;
							background-image: url("~@/assets/home/girl-tattoo.png");
							background-size: contain;
							left: -2%;
							bottom: 15%;
							@include up($md) {
								left: 0;
							}
						}

						&__ul {
							max-width: 500px;
							margin: 0 auto;
							display: grid;
							grid-template-columns: repeat(2, 1fr);
						}

						&__icon {
							width: 56px;
							height: 56px;
							margin-right: 10px;
						}

						& li {
							margin-bottom: 60px;
							display: flex;
							align-items: center;
							justify-content: center;


							&:first-child {
								margin-top: unset;
							}
						}
					}
				}
			}
		}

		/*&:not(:last-child) {
			&:after {
				position: absolute;
				content: "";
				top: 45px;
				width: 100%;
				height: 1px;
				opacity: 0.5;
				border: 1px solid rgba(0, 0, 0, 0.16);
				left: 0;
				z-index: -1;
				@include up($sm) {
					right: -35px;
					height: 21px;
					left: unset;
					border: unset;
					opacity: 1;
					border-right: 1px solid $black;
					top: 5px;
				}
			}
		}*/

	}

	&__logo {
		@include up($sm) {
			padding-left: 10px;
		}

		& img {
			height: 26px;
			margin: 0 auto;
			display: block;
		}
	}

	// корректирует цвета menu elements для верхней шапки
	.menu-items a {
		color: $black500 !important;
		z-index: 2;

		&:hover,
		&:focus,
		&:active {
			color: $primary500 !important;
		}
	}

	&__right {

		justify-content: flex-end;
		@include up($lg) {
			position: absolute;
			right: 130px;
		}
		@include up($xl) {
			position: unset;
		}
	}

	&__items--logo {
		margin: auto;
		@include up($sm) {
			margin: 0;
		}
	}


	&__burger {
		cursor: pointer;
		width: 45px;
		height: 26px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 4;
	}

	&__burger-icon {
		display: flex;
		width: 45px;
		height: 3px;
		flex-direction: column;
		justify-content: center;
		align-items: end;

		span {
			width: 100%;
			height: 3px;
			background-color: $blue-1;
			margin: -7px;
			border-radius: 50px;

			&:first-child {
				width: 25px;
			}

			&:nth-child(2) {
				width: 38px;
			}

			&:last-child {
				width: 44px;
			}

			@include transition();
		}

		&--closed {
			span {
				&:first-child {
					transform: translateY(-8.5px) rotate(45deg);
					width: 100%;
				}

				&:nth-child(2) {
					transform: translateY(3.5px) rotate(-45deg);
					width: 100%;
				}

				&:nth-child(3) {
					display: none;
				}
			}
		}
	}

}

.logo {
	@include up($lg) {
		width: 96px;
		height: 50px;
	}
}

</style>

<script>
import Cookies from 'js-cookie';

import MenuItems from "../../components/menu-items.vue";
import BurgerMenu from "../../components/burger-menu";
import SelectCustom from "../../components/select-custom";
import SiteSearch from "../../components/site-search";
import config from '@/config';


export default {
	name: 'the-header',
	components: {SiteSearch, SelectCustom, BurgerMenu, MenuItems},
	data() {
		return {
			activeSelect: false,
			//languageCode: null,
			search: "",
		}
	},
	computed: {
		isBurgerMenuShown() {
			return this.$store.state.isBurgerMenuShown;
		},
		texts() {
			return {
				search: this.$store.state.common?.commontext?.SEARCH_PLACEHOLDER,
			}
		},
		langs() {
			return config.LANGUAGES;
		},
		currentLanguage() {
			let lang = config.LANGUAGES.find(el => el.code === this.common.languageCode);
			if (!lang) lang = config.LANGUAGES[0];
			return lang;
		},
		currentLanguageName() {
			return this.currentLanguage.name;
		},
		currentLangCode() {
			return this.currentLanguage.code;
		},
		withSearch() {
			return this.$route.name !== "Search";
		},
		common() {
			return this.$store.state.common;
		},
	},
	methods: {
		toggleBurgerMenu() {
			this.$store.state.isBurgerMenuShown = !this.$store.state.isBurgerMenuShown;
		},
		onMenuItemSelected(item) {
			this.$store.state.isBurgerMenuShown = false;
			/*setTimeout(()=>{
			},700);*/
		},
		onLang(lang) {
			Cookies.set('lang', lang.code.toUpperCase(), {expires: 7, domain: "shu.eu.com"});

			if (lang.code === this.currentLangCode) return;
			window.location.href = lang.host + this.$route.path;
		},
	},
}

</script>