<template>
  <section class="other-products">
    <v-container>
      <v-row>
        <v-col cols="12"><h2>Покупают вместе</h2></v-col>
        <v-col cols="12" sm="6" lg="3" v-for="(item, index) of items" :key="'item' + index">
          <v-img :src="item.img"/>
          <div class="other-products__item pa-3 pt-4">
            <div class="text-left mb-4 size-2 color-blue">{{ item.name }}</div>
            <div class="d-flex">
              <div v-for="(color, index) of item.colors" :key="'color' + index" :style="hexStyle(color)"
                   class="other-products__item-color mr-1"></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

  </section>
</template>

<script>
export default {
  name: "other-products",
  data() {
    return {
      items: [
        {
          img: require("@/assets/product/other-product/1.jpg"),
          name: "Mascara Extreme Volume - BLACK MANIA",
          colors: [
            "272520",
            "3980EA",
            "AD1A8D"
          ]
        },
        {
          img: require("@/assets/product/other-product/2.jpg"),
          name: "Mascara Extreme Volume - BLACK MANIA",
          colors: [
            "DBC087",
            "D08FDB",
            "AD1A8D",
            "272520"
          ]
        },
        {
          img: require("@/assets/product/other-product/3.jpg"),
          name: "Mascara Extreme Volume - BLACK MANIA",
          colors: [
            "272520",
            "3980EA",
            "AD1A8D"
          ]
        },
        {
          img: require("@/assets/product/other-product/4.jpg"),
          name: "Mascara Extreme Volume - BLACK MANIA",
          colors: [
            "AB8D60",
            "8C5D4D",
            "AD4B2A",
            "CC9E69"
          ]
        },
      ]
    }
  },
  methods: {
    hexStyle(color) {
      return {
        "background-color": "#" + color
      };
    },
  }
}
</script>

<style lang="scss">
.other-products {

  &__item {
    background-color: $white;

    &-color {
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
  }

  .row {
    h2 {
      margin-bottom: 40px;
      @include up($lg) {
        margin-bottom: 45px;
      }
    }

    & > div {
      padding-bottom: 14px;
      @include up($sm) {
        padding: 0 7px 14px 7px;
      }
      @include up($lg) {
        padding: 0 14px;
      }

    }
  }
}
</style>