<template>
    <section class="novelties-item">
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" class="d-flex flex-column justify-center align-center">
                    <div class="d-flex flex-column justify-center align-center relative z-index-2">
                        <product :item="product"/>
                        <router-link :to="{name:'ProductPage', params:{code:product.CODE}}"
                                     class="novelties-item__link mb-8">
                            view
                        </router-link>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" class="d-none d-sm-block">
                    <v-img :src="product.NEW_ITEM2_IMG"/>
                </v-col>

            </v-row>
        </v-container>
    </section>
</template>

<script>
import Product from "../../components/product";
export default {
    name: "novelties-item",
    components: {Product},
    computed: {
        product() {
            return this.$store.state.page?.ProductNewListController?.items[1];
        },

    }
}
</script>

<style lang="scss">
.novelties-item {
    position: relative;
    &__img {
        width: 168px;
        @include up($sm) {
            width: unset;
        }
    }

    &__link {
        border: 1px solid #1A1A1A;
        background: #FFFFFF;
        padding: 18px 70px;
        font-family: $directions;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: $black500 !important;
        @include transition();
        &:hover {
            border: 1px solid $primary500;
            color: $primary500 !important;
        }
    }

    &__bg {
        position: absolute;
      pointer-events: none;
    }
}
</style>