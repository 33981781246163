<template>
    <section class="novelties-intro">

        <v-container>
            <v-row class="relative">
                <v-col cols="12" class="novelties-intro__item --1">
                </v-col>
                <img class="novelties-intro__img --1"
                     src="~@/assets/product/product-page-vegan/2.png">
                <img class="novelties-intro__img --2"
                     src="~@/assets/product/product-page-vegan/1.png">
                <v-col cols="12" class="novelties-intro__item --2">
                    <h1 class="size-4 color-black-2 text-center relative">{{ texts.new }}</h1>
                </v-col>
            </v-row>
        </v-container>

        <v-img class="novelties-intro__photo d-sm-none"
               src="~@/assets/novelties/novelties-intro/xs.jpg"/>
        <v-img class="novelties-intro__photo d-none d-sm-block"
               src="~@/assets/novelties/shadows.jpeg"/>
    </section>
</template>

<script>
export default {
    name: "novelties-intro",
    computed: {
        texts() {
            return {
                new: this.$store.state.common?.buttons?.MAIN_NEW,
            }
        },
    }
}
</script>

<style lang="scss">
.novelties-intro {

    & h1 {
        margin-top: 0;
        text-shadow: 0px 0px 20px rgba(169, 11, 29, 0.25);
    }

    &__item {
        &.--1 {
            padding-top: 220px;
            @include up($sm) {
                padding-top: 260px;
            }
        }

        &.--2 {
            padding-bottom: 20px;
            @include up($sm) {
                padding-bottom: 80px;
            }
        }

        h1 {
            &:before {
                @include up($sm) {
                    position: absolute;
                    content: "";
                    width: 52px;
                    height: 55px;
                    background-image: url("~@/assets/novelties/novelties-intro/Star.svg");
                    top: -100px;
                    left: 50%;
                    transform: translate(-50%, 50%);
                }
            }
        }

    }

    &__img {
        position: absolute;


        &.--1 {
            width: 250px;
            left: 50%;
            top: 0;
            transform: translateX(-100%);
            @include up($sm) {
                top: unset;
                left: -110px;
                bottom: -30px;
                transform: unset;
                width: 390px;
            }
        }

        &.--2 {
            width: 180px;
            right: -55px;
            top: 25px;
            transform: rotate(25.74deg);
            @include up($sm) {
                right: -85px;
                top: unset;
                bottom: -100px;
                width: 390px;
            }
        }
    }

    &__photo {
        width: 100%;
        position: relative;
        z-index: 1;
        @include up($sm) {
            height: 686px;
        }
    }
}
</style>