<template>
    <div class="select-custom"
         @click.stop="onItemClick"
         :class="isActive ? 'select-custom--active ' : 'animate__animated animate__fadeIn'"
    >
        <span class="d-block text-left select-custom__value">
            {{ selectedItem ? selectedItem.text : (texts.choose) }}

        </span>

        <ul v-if="isActive && options">
            <li v-for="(item, index) of options" :key="index"
                class="text-left"
                @click.stop="onSelect(item)">
                {{ item.text }}
            </li>
        </ul>
    </div>
</template>


<script>

import config from '@/config';

let id = 0;

export default {
    name: "select-custom",
    props: ["options", "value", "text"],
    data: () => {
        return {
            selectedItem: null,
            disabled: false,
            isActive: false,
            //isOpen: false,
            id: 0
        }
    },
    computed: {
        texts() {
            return {
                choose: this.$store.state.common?.commontext?.CHOOSE,
            }
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue, oldValue) {
                //console.log("newValue:", newValue, "oldValue:", oldValue );
                this.selectedItem = this.options?.find(el => el.value === newValue);
            }
        },
        '$store.state.activeSelectId'(newValue, oldValue) {
            //console.log("Someone touches selects..."+newValue);
            if (this.id !== newValue) this.isActive = false;
        }
    },
    methods: {
        onSelect(item) {
            this.isActive = false;
            this.selectedItem = item;
            this.$emit('input', item.value);
            this.$emit('change', item);
        },
        onItemClick() {
            this.isActive = !this.isActive;

            if (this.isActive) {
                this.$store.state.activeSelectId = this.id;
            }

            window.location.href = lang.host + this.$route.path;
        },
        onDocumentClick() {
            this.isActive = false;
        }
    },
    mounted() {
        id++;
        this.id = id;

        document.addEventListener("click", this.onDocumentClick);
    },
    destroyed() {
        document.removeEventListener("click", this.onDocumentClick);
    }

}

</script>

<style lang="scss">
.select-custom {
    display: flex;
    align-items: center;

    padding: 2px 18px 0 18px;
    background-color: $additional100;
    box-shadow: $default-shadow;
    //border-radius: 20px;
    font-size: 18px;
    width: 296px;
    height: 40px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    @include transition("box-shadow");

    &:hover {
        box-shadow: $default-shadow-hover;
    }

    & ul {
        position: absolute;
        padding: 8px 0 12px 0 !important;
        background-color: $additional100;
        //box-shadow: $default-shadow-hover;
        //border-radius: 20px;
        font-size: 18px;
        width: 100%;
        top: 35px;
        left: -17px;
        z-index: 2;
        max-height: 250px;
        overflow-y: auto;
    }

    & li {
        padding: 5px 17px;
        /*&:not(:first-child) {
            margin-top: 16px;
        }*/
        &:hover {
            background: $white;
            //color: $primary500 !important;
        }
    }

    &__value {
        position: relative;
        width: 100%;
        &::after {
            position: absolute;
            content: "";
            background-image: url("~@/assets/arrow-down.svg");
            width: 15px;
            height: 13px;
            right: 0;
            top: 10px;
        }
    }

    &--active {
        .select-custom__value {
            &::after {
                background-image: url("~@/assets/arrow-up.svg");
            }
        }
    }



    /*&::after {
        position: absolute;
        content: url("~@/assets/caret.svg");
        width: 10px;
        height: 5px;
        top: 8px;
        right: 15px;
    }*/


    /*&--active {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        & ul {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        &::after {
            top: 26px;
            transform: rotate(180deg);
        }
    }*/
}
</style>