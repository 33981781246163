<template>
    <div v-if="isOpen" class="modal-form__shadow" @click.self="$emit('close')">
        <v-form class="modal-form"
                @submit.prevent="submit"
                ref="questionForm">

            <template v-if="isSubscribed">
                <h3 v-if="languageCode === 'ru'" class="modal-form__title">Спасибо за Ваш вопрос!</h3>
                <h3 v-else class="modal-form__title">Thanks for the question!</h3>
            </template>

            <template v-else>
                <button type="button" class="modal-form__close"
                        @click="$emit('close')">
                    <span></span>
                    <span></span>
                </button>
                <h3 class="modal-form__title"> {{ texts.formHead }} </h3>
                <div class="modal-form__content">
                    <v-row>
                        <v-col cols="12">
                            <label>
                                <input v-model="form.NAME" type="text"
                                       :placeholder="texts.formInput1" maxlength="64">
                            </label>
                        </v-col>
                        <v-col cols="12" class="mt-2">
                            <label>
                                <input v-model="form.EMAIL" type="email"
                                       name="email" :placeholder="texts.formInput2"
                                       maxlength="64">
                            </label>
                        </v-col>
                        <v-col cols="12" class="mt-2">
                            <label>
                                <textarea v-model="form.MSG" type="text"
                                          name="comment"
                                          :placeholder="texts.formTextarea"
                                          maxlength="64"/>
                            </label>
                        </v-col>

                    </v-row>
                    <!--<div v-html="texts.formArgee"/>-->

                    <p v-if="error" class="mt-3 mb-0 join__error" v-html="error"></p>
                    <p v-html="texts.policy" class="mt-6 text-center text-md-left"></p>

                </div>
                <button type="submit" :disabled="isLoading">
                    <switch-button v-if="isLoading">
                        <pulse-loader :loading="true" color="#C10016" size="5px"/>
                    </switch-button>
                    <switch-button v-else v-html="texts.btnSend"/>
                </button>
            </template>
        </v-form>
    </div>
</template>

<style lang="scss">
.modal-form {
    position: relative;
    z-index: 10;
    background: linear-gradient(180deg, #D5C39D 0%, #E4D7C8 0.01%, #FBF4E8 100%);
    padding: 32px;
    width: 100%;

    @include up($sm) {
        max-width: 541px;
        //border-radius: 32px;
    }
    @include up($md) {
        padding: 64px;
    }

    &__close {
        position: absolute;
        width: 35px;
        height: 35px;
        right: 15px;
        top: 20px;
        @include transition();
        &:hover {
            opacity: 0.6;
        }
        span {
            display: block;
            width: 100%;
            height: 3px;
            background-color: #0C2564;
            margin: -8px;
            border-radius: 50px;
            transition: all 0.2s ease-in-out;
            &:first-child {
                transform: translateY(3.5px) rotate(45deg);
            }

            &:last-child {
                transform: translateY(8.5px) rotate(-45deg);
            }
        }
    }

    &__shadow {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.39);
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        font-size: 48px;
        line-height: 53px;
        text-align: center;
        margin-bottom: 16px;
        @include up($md) {
            margin-bottom: 32px;
        }
    }

    &__content {
        margin-bottom: 20px;

        a {
            color: $primary500 !important;
        }
    }

    & input {
        max-height: 50px;
    }

    & label input {
        padding: 13px 18px;
        background-color: $additional100;
        box-shadow: $default-shadow;
        //border-radius: 999px;
        width: 100%;
        margin-bottom: 16px;
        @include up($md) {
            margin-bottom: 15px;
        }
    }

    & textarea {
        padding: 13px;
        background-color: $additional100;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.06);
        //border-radius: 999px;
        width: 100%;
        margin-bottom: 16px;
        @include up($md) {
            margin-bottom: 32px;
        }

    }

    & textarea {
        height: 100px;
        //border-radius: 32px;
        @include up($md) {
            height: 161px;
        }
    }

    &__submit {
        padding: 64px;
        background-image: url("~@/assets/bg/1344-bg-optimized.jpeg");
        background-size: cover;
        //border-radius: 32px;
        max-width: 541px;
        position: absolute;
        top: 83%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        line-height: 23px;
    }

    .custom-checkbox > span {
        display: flex;
        align-items: baseline;
        text-align: left;

        &::before {
            margin-right: 16px;
        }
    }
}

</style>

<script>
import SwitchButton from "./switch-button";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import axios from "axios";
import Config from "@/config";

export default {
    name: "ModalForm",
    components: {SwitchButton, PulseLoader},
    props: {
        isOpen: {
            type: Boolean,
            default: true,
        },
    },
    emits: {
        close: null,
        isOpen: null,
    },
    data() {
        return {
            isSubscribed: false,
            form: {
                NAME: "",
                EMAIL: "",
                MSG: ''
            },
            error: "",
            isLoading: false
        }
    },
    computed: {
        languageCode() {
            return this.$store.state.common.languageCode;
        },
        texts() {
            return {
                formHead: this.$store.state.common?.commontext?.FORM_HEAD,
                formInput1: this.$store.state.common?.commontext?.JOIN_NAME,
                formInput2: this.$store.state.common?.commontext?.FORM_INPUT2,
                formTextarea: this.$store.state.common?.commontext?.FORM_TEXTAREA,
                formAgree: this.$store.state.common?.commontext?.FORM_AGREEMENT,
                formSend: this.$store.state.common?.commontext?.FORM_SUBMIT,
                btnSend: this.$store.state.common?.buttons?.SEND,
                valid1: this.$store.state.common?.commontext?.JOIN_VALID1,
                valid2: this.$store.state.common?.commontext?.JOIN_VALID2,
                valid3: this.$store.state.common?.commontext?.JOIN_VALID3,
                policy: this.$store.state.common?.commontext?.POLICY
            }
        },
    },
    methods: {
        submit() {

            const reName = /^.{2,}$/;
            if (!reName.test(String(this.form.NAME).toLowerCase())) {
                return this.error = this.texts?.valid1 ? this.texts?.valid1 : 'Пожалуйста, введите Ваше имя';
            }

            const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!reEmail.test(String(this.form.EMAIL).toLowerCase())) {
                return this.error = this.texts?.valid2 ? this.texts?.valid2 : "Пожалуйста, введите Ваш email";
            }

            //if ( !this.form.agree) return this.error = "Необходимо принять пользовательское соглашение";

            this.error = false;
            this.isLoading = true;

            const formData = new FormData();
            Object.keys(this.form).forEach(key => formData.append(key, this.form[key]));
            //const imagefile = document.querySelector('input[type="file"]');
            //formData.append("FILE", imagefile.files[0]);

            return axios.post(Config.SUBMIT_URL2, this.form)


                .then((response) => {
                    console.log('API Response: ', response);
                    if (response.data?.error) {
                        if (response.data.code === Config.ERROR_CODE_WRONG_PARAMS) this.error = "Отправлены неверные параметры.<br />Пожалуйста, проверьте и попробуйте снова."
                        else this.error = "Произошла неизвестная ошибка.<br />Пожалуйста, попробуйте отправить еще раз.";

                    } else {
                        this.isSubscribed = true;

                        window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                    }
                })
                .catch((error) => {
                    console.warn('API request error: ', error);
                    this.error = error;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        /*submit() {
            if (!this.validate() || !this.form.agree) return;

            this.isLoading = true;
            this.$store.dispatch('post', {
                action: 'CallRequestController',
                params: {
                    'name': this.form.name,
                    'phoneoremail': this.form.contact,
                    'agree': this.form.agree,
                    'text': this.form.text
                }
            }).then((response) => {
                this.requestStatus = 'success';
            }).catch((error) => {
                console.warn('Request error:', error);
                this.requestStatus = 'error';
            }).finally(() => {
                this.isLoading = false;
                setTimeout(() => {
                    this.requestStatus = null;
                }, 5000)
            })


            /*const reName = (/^.{2,}$/);
            if (!reName.test(String(this.form.name).toLowerCase())) {
                return this.error = "Пожалуйста, введите Ваше имя";
            }

            //todo validate tel
            if (!reName.test(String(this.form.contact).toLowerCase())) {
                return this.error = "Пожалуйста, введите номер телефона или email";
            }

            if (!reName.test(String(this.form.text).toLowerCase())) {
                return this.error = "Пожалуйста, введите текст сообщения";
            }

            if (!this.form.agree) return this.error = "Необходимо принять пользовательское соглашение";


            this.$store.dispatch('post', {
                action: 'CallRequestController',
                params: {'name': this.form.name, 'phoneoremail': this.form.contact, 'formtext': this.form.text}
            }).then(() => {
                this.$router.push({name: 'About'});
            }).catch((error) => {
                console.log('error', error);
                this.error = 'Всё плохо!';
            })*/
    }
}
</script>