<template>
    <div class="novelties">
        <v-img class="d-sm-none stripe" width="100%" src="~@/assets/home/SHU-runner2.jpg"/>
        <novelties-intro/>
        <novelties-item/>
        <new-items/>
        <novelties-item2/>
        <directions class="d-sm-none"/>
        <insta-shu v-if="languageCode !== 'en'"/>
        <directions class="d-none d-sm-block"/>
        <follow/>
    </div>
</template>

<script>
import Follow from "@/views/follow";
import InstaShu from "@/views/insta-shu";
import Directions from "@/views/directions";
import OtherProducts from "@/components/other-products";
import NoveltiesIntro from "@/views/novelties/novelties-intro";
import NoveltiesItem from "@/views/novelties/novelties-item";
import NoveltiesItem2 from "@/views/novelties/novelties-item2";
import NewItems from "@/views/new-items";

export default {
    name: "novelties",
    components: {NewItems, NoveltiesItem, NoveltiesItem2, NoveltiesIntro, OtherProducts, Directions, InstaShu, Follow},
    data() {
        return {}
    },
    computed: {
        languageCode() {
            return this.$store.state.common.languageCode;
        },
    }
}
</script>

<style lang="scss">
.novelties {
    & .directions__list:before {
        content: unset;
    }

    & .new-items {
        padding: 0;
        @include up($sm) {
            padding-top: 125px;
        }
    }

    &-item {
        margin: 80px 0;
    }


    .other-products {
        @include up($sm) {
            margin: 130px 0;
        }

        h2 {
            display: none;
        }

        .container {
            @include up($sm) {
                padding: 0;
            }
        }
    }

    .directions {
        padding-bottom: 60px;
        @include up($sm) {
            padding-bottom: 0;
        }
    }

    .insta-shu {
        margin: 80px 0 120px;
        @include up($sm) {
            margin: 145px 0 160px;
        }
    }

    .follow {
        margin-bottom: 50px;
        @include up($sm) {
            margin-top: 150px;
        }

        & .container {
            @include up($sm) {
                padding: 0 50px;
            }
            @include up($md) {
                padding: 0 88px;
            }
        }
    }

    .new-items {
        h2 {
            display: none;
        }

        &::after {
            @include up($sm) {
                display: none;
            }
        }
    }


}
</style>