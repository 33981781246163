<template id="new-items">
    <section v-if="products && products.length"
             class="new-items text-center">
        <v-container>
            <h2 v-html="texts.new"></h2>
            <div class="d-flex flex-wrap justify-center mb-8">
                <div v-for="(item,index) of displayedProducts" :key="index" >
                    <product :item="item"/>
                </div>
            </div>
        </v-container>
    </section>
</template>

<style lang="scss">
.new-items {

    & .product__color {
        width: 31px;
        height: 31px;
    }

    @include up($lg) {
        position: relative;
        overflow: hidden;
        &::after {
            @include up($lg) {
                position: absolute;
                content: "";
                background-image: url("~@/assets/home/tiger-tat.png");
                width: 586px;
                height: 840px;
                opacity: 0.3;
                z-index: -1;
                right: -40px;
                top: -30px;
            }
        }
    }


    &__dots {
        span {
            width: 12px;
            height: 12px;
            background-color: $black500;
            opacity: 0.2;
            transform: rotateZ(45deg);
            border-radius: unset;

            &:not(:last-child) {
                margin-right: 16px;
            }

            &:active,
            &:hover {
                opacity: 1;
                width: 16px;
                height: 16px;
            }
        }
    }

    .swiper-pagination {
      bottom: 0;
        &-bullet {
          width: 12px;
          height: 12px;
          background-color: $black500;
          opacity: 0.2;
          transform: rotateZ(45deg);
          border-radius: unset;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }

        &-bullet-active {
            width: 16px;
            height: 16px;
            background: $black500;
            opacity: 1;
        }
    }
}
</style>

<script>
import Swiper, {Pagination} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
//import 'swiper/css';
//import "swiper/css/pagination";

import BtnLink from "../components/btn-link";
import Product from "../components/product";
import BtnBlue from "../components/btn-blue";

const MAX_ITEMS = 4;

export default {
    name: 'NewItems',
    components: {BtnBlue, Product, BtnLink},
    props: ["btn"],
    data() {
        return {
            currentSlide: 1,
            withAllItems: false,
        }

    },
    computed: {
        products() {
            return this.$store.state.page?.HomeController?.newProducts;
        },

        displayedProducts() {
            return this.withAllItems ? this.products : this.products?.slice(0, this.maxItems);
        },
        maxItems() {
            return MAX_ITEMS;
        },
        texts() {
            return {
                new: this.$store.state.page?.HomeController?.texts?.MAIN_NEW,
            }
        },
        buttons() {
            return {
                more: this.$store.state.common?.buttons?.MORE,
            }
        }
    },
    methods: {
        onSlideChange() {
            this.currentSlide = (this.$refs.swiper?.$swiper?.activeIndex || 0) + 1;
        },
        slideNext() {
            this.$refs.swiper?.$swiper?.slideNext(500);
        },
        slidePrev() {
            this.$refs.swiper?.$swiper?.slidePrev(500);
        },
    },
    mounted() {
        setTimeout(() => {
            this.$store.state.swipers.NewItems = this.$refs.swiper?.$swiper;
        }, 300);

      setTimeout(() => {
        const swiper = new Swiper('#new-items-swiper', {
          freeMode: true,
          slidesPerView: 1,
          modules: [Pagination],
          spaceBetween: 10,
          loop: false,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            960: {
              slidesPerView: 2,
            },
            1280: {
              slidesPerView: 3,
            },
          }
        });
      }, 50);
    }
}
</script>