<template>
    <section v-if="products && products.length"
             class="popular-items text-center">
        <v-container>
            <h2 v-html="texts.popular"></h2>


            <!--<div ref="swiper" class="swiper d-lg-none" id="popular-items-swiper">
              <div class="swiper-wrapper">
                <div v-for="(item, index) of products" :key="index" class="swiper-slide">
                  <product :item="item"/>
                </div>
              </div>
              <div class="swiper-pagination mb-1"></div>
            </div>-->

            <v-row class="d-flex mb-8">
                <v-col cols="12" sm="6">
                    <product v-for="(item,index) of displayedProducts" :key="index" :item="item"/>
                </v-col>
                <v-col cols="12" sm="6"  class="d-none d-sm-block popular-items__col">
                    <v-img class="popular-items__img" src="~@/assets/popular/popular.jpg"/>
                </v-col>
            </v-row>

            <!--      <div class="popular-items__dots d-flex justify-center align-center d-lg-none">
                    <span></span>
                  </div>-->
            <!--<btn-blue v-if="products.length > maxItems && !withAllItems" @click="withAllItems = true"
                      class="d-none d-lg-inline-block">{{ buttons.more }}
            </btn-blue>-->
        </v-container>
    </section>
</template>

<style lang="scss">
.popular-items {

    & .product__color {
        width: 31px;
        height: 31px;
    }

    .container {
        @include up($sm) {
            padding: 0 48px;
        }
        @include up($md) {
            padding: 0 68px;
        }
        @include up($lg) {
            padding: 0 88px;
        }

    }

    @include up($lg) {
        position: relative;
        overflow: hidden;
    }

    &__col {
        position: relative;
    }

    &__img {
        width: auto;
        height: 772px;
        @include up($lg) {
            //width: 770px;
            height: 972px;
        }
    }

    &__dots {
        span {
            width: 12px;
            height: 12px;
            background-color: $black500;
            opacity: 0.2;
            transform: rotateZ(45deg);
            border-radius: unset;

            &:not(:last-child) {
                margin-right: 16px;
            }

            &:active,
            &:hover {
                opacity: 1;
                width: 16px;
                height: 16px;
            }
        }
    }

    .swiper-pagination {
        bottom: 0;

        &-bullet {
            width: 12px;
            height: 12px;
            background-color: $black500;
            opacity: 0.2;
            transform: rotateZ(45deg);
            border-radius: unset;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }

        &-bullet-active {
            width: 16px;
            height: 16px;
            background: $black500;
            opacity: 1;
        }
    }
}
</style>

<script>
import BtnBlue from "../components/btn-blue";
import BtnLink from "../components/btn-link";
import Product from "../components/product";
import Swiper, {Pagination} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

const MAX_ITEMS = 4;

export default {
    name: 'PopularItems',
    components: {Product, BtnLink, BtnBlue},
    props: ["btn"],
    data() {
        return {
            currentSlide: 1,
            withAllItems: false,
        }
    },
    computed: {
        products() {
            return this.$store.state.page?.HomeController?.popularProducts;
        },
        displayedProducts() {
            return this.withAllItems ? this.products : this.products?.slice(0, this.maxItems);
        },
        maxItems() {
            return MAX_ITEMS;
        },
        texts() {
            return {
                popular: this.$store.state.page?.HomeController?.texts?.MAIN_POPULAR,
            }
        },
        buttons() {
            return {
                more: this.$store.state.common?.buttons?.MORE,
            }
        }
    },
    methods: {
        onSlideChange() {
            this.currentSlide = (this.$refs.swiper?.$swiper?.activeIndex || 0) + 1;
        },
        slideNext() {
            this.$refs.swiper?.$swiper?.slideNext(500);
        },
        slidePrev() {
            this.$refs.swiper?.$swiper?.slidePrev(500);
        },
    },
    mounted() {
        setTimeout(() => {
            this.$store.state.swipers.PopularItems = this.$refs.swiper?.$swiper;
        }, 300);

        setTimeout(() => {
            const swiper = new Swiper('#popular-items-swiper', {
                freeMode: true,
                slidesPerView: 1,
                modules: [Pagination],
                spaceBetween: 10,
                loop: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    960: {
                        slidesPerView: 2,
                    },
                    1280: {
                        slidesPerView: 3,
                    },
                }
            });
        }, 50);
    }
}
</script>