<template>
  <section class="insta-shu text-center">
    <v-container>
      <h2>{{ buttons.menuBuy }}?</h2>
      <!--<swiper ref="swiper" :options="swiperOption" @slideChange="onSlideChange"
              class="insta-shu__items mb-8 d-lg-none">
          <swiper-slide v-for="(item, index) of items" :key="index" class="insta-shu__item">
              <a :href="item.link" target="_blank" class="d-block">
                  <img :src="item.img" width="296px" alt="">
              </a>
          </swiper-slide>
      </swiper>-->
      <v-row class="insta-shu__list">
        <v-col v-for="(item, index) of items" :key="index" class="insta-shu__item" cols="12" md="4" lg="3">
          <a :href="item.link" target="_blank" class="d-block">
            <img :src="item.img" width="296px" alt="">
          </a>
        </v-col>
      </v-row>

      <!--<div class="insta-shu__dots d-flex justify-center align-center d-xs-flex d-lg-none">
          <span></span>
          <span></span>
          <span></span>
      </div>-->


      <btn-blue :to="{name: 'About'}" v-if="buttons.mainShow">{{ buttons.mainShow }}</btn-blue>
    </v-container>
  </section>
</template>

<script>
import '../plugins/vue-awesome-swiper'
import BtnLink from "../components/btn-link";
import BtnBlue from "../components/btn-blue";

export default {
  name: 'insta-shu',
  data() {
    return {
      currentSlide: 1,
    }
  },
  computed: {
    items() {
      return this.$store.state.currentPage?.cases?.map(el => {
        return {
          img: el.DETAIL_PICTURE,
          link: el.LINK
        };
      });
    },
    texts() {
      return {
        buy: this.$store.state.common?.texts?.MAIN_MODELS,
      }
    },
    buttons() {
      return {
        mainShow: this.$store.state.common?.buttons?.MAIN_SHOW,
        menuBuy: this.$store.state.common?.buttons?.MENU_BUY,
      }
    }
  },
  components: {BtnBlue, BtnLink},
  props:
      ["btn"],

  methods: {
    onSlideChange() {
      this.currentSlide = (this.$refs.swiper?.$swiper?.activeIndex || 0) + 1;
    },
    slideNext() {
      this.$refs.swiper?.$swiper?.slideNext(500);
    },
    slidePrev() {
      this.$refs.swiper?.$swiper?.slidePrev(500);
    },
  },
  mounted() {
    setTimeout(() => {
      this.$store.state.swipers['insta-shu'] = this.$refs.swiper?.$swiper;
    }, 300);
  }
}
</script>

<style lang="scss">
.insta-shu {

  & .container {
    padding: 0px 48px;
  }

  &__list {
    justify-content: center;
    align-items: center;
    @include up($lg) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
    }
  }

  &__item {
    max-width: 296px;

    &:not(:last-child) {
      margin-bottom: 20px;
      @include up($lg) {
        margin-bottom: 0;
      }
    }

    & img {
      width: fit-content;
      height: auto;
      @include transition();
      border-radius: 16px;
      border: 5px solid transparent;
      max-width: 280px;

      &:hover {
        //тborder: 5px solid $white;
      }
    }

  }


}
</style>