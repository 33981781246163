<template>
    <section class="directions text-center">
        <v-container>
            <!--<h2 v-html="texts.directions" class="mb-8 mb-md-16"></h2>-->
            <div class="directions__list pb-8 pb-md-16">
                <div v-for="(item, index) of items" class="directions__item">
                    <router-link :to="{name:'Direction', params:{dir:item.dir}}" class="directions__link">
                        <div>
                            <!--<img :src="item.img" alt="" class="d-lg-none">-->
                            <img :src="item.imgLG" alt="">
                            <!--<span></span>-->
                        </div>
                        <h3 class="directions__item-title">
                            {{ item.name }}
                        </h3>
                    </router-link>
                </div>
            </div>
        </v-container>
    </section>
</template>

<style lang="scss">
.directions {
    @include up($lg) {
    }

    h2 {
        @include up($md) {
            font-size: 60px;
            line-height: 70px;
        }
    }

    &__list {
        position: relative;
        @include up($sm) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            align-items: baseline;
            grid-gap: 30px;
        }
    }

    &__item {
        display: flex;
        justify-content: center;

        &:not(:last-child) {
            margin-bottom: 66px;
            @include up($lg) {
                margin-bottom: 0;
                justify-content: center;
            }
        }

        &:hover {
            img {
                transform: rotate(5deg);
            }
        }

        &:nth-child(4) {
            display: none;
        }
    }

    img {
        object-fit: cover;
        max-height: 252px;
        max-width: 200px;
        @include up($md) {
            //max-width: unset;
            //max-height: unset;
        }
    }

    &__link {
        color: $white !important;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        max-width: 296px;
        height: auto;
        border-radius: 16px;
        position: relative;
        z-index: 1;
        transition: all 0.3s ease;

        & div span {
            transition: all 0.3s ease;
            display: block;
            width: 296px;
            height: 132px;
            border-radius: 28px;
            top: 0;
            left: 0;

            &:hover {
                background-color: rgba(193, 0, 22, 0.65);
            }
        }
    }

    &__item-title {
        font-weight: 400;
        font-size: 40px;
        line-height: 30px;
        text-align: center;
        color: #A90B1D;
        margin-top: 50px;
        text-shadow: 0px 0px 5px rgba(169, 11, 29, 0.4);
        z-index: 10;
        font-family: $directions;
    }
}
</style>

<script>
export default {
    name: "directions",
    data() {
        return {
            /*     items: [
                    {name: 'Лицо', dir: 'face', img: require('@/assets/directions/face.png'), imgLG: require('@/assets/directions/face-lg.png')},
                    {name: 'Глаза', dir: 'eyes', img: require('@/assets/directions/eyes.png'), imgLG: require('@/assets/directions/eyes-lg.png')},
                    {name: 'Брови', dir: 'brows', img: require('@/assets/directions/brows.png'), imgLG: require('@/assets/directions/brows-lg.png')},
                    {name: 'Губы', dir: 'lips', img: require('@/assets/directions/lips.png'), imgLG: require('@/assets/directions/lips-lg.png')},
                    {name: 'Ногти', dir: 'nails', img: require('@/assets/directions/nails.png'), imgLG: require('@/assets/directions/nails-lg.png')},
                  ]*/
        }
    },
    computed: {
        items() {
            return this.$store.state.common?.sections?.map(el => ({
                name: el.NAME,
                dir: el.CODE,
                img: el.UF_DIRECTION_PIC,
                imgLG: el.PICTURE
            }))
        },
        texts() {
            return {
                //directions: this.$store.state.page?.HomeController?.texts?.MAIN_DIRECTIONS,
            }
        }
    }
}
</script>