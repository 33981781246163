<template>
	<div class="select">
		<div class="select__lang">
			<span class="select__lang-current item">{{ currentLanguageName }}</span>
			<div class="select__lang-selector">
				<div class="select__lang-items">
					<template v-for="(item,index) of langsMobile">
						<span @click="onLang(item)" :class="{active:item.code===currentLangCode}">
							{{ item.name }}
						</span>
						<span v-if="index<langsMobile.length-1" class="dot"></span>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Cookies from "js-cookie";
import config from '@/config';

export default {
	name: "language-select",
	data: () => ({
		activeSelect: false,
	}),
	computed: {
		notSelectList() {
			return this.texts?.enMob;
		},
		langsMobile() {
			return config.LANGUAGES_MOBILE;
		},
		currentLanguage() {
			let lang = config.LANGUAGES_MOBILE.find(el => el.code === this.common.languageCode);
			if (!lang) lang = config.LANGUAGES_MOBILE[0];
			return lang;
		},
		currentLanguageName() {
			return this.currentLanguage.name;
		},
		currentLangCode() {
			return this.currentLanguage.code;
		},
		common() {
			return this.$store.state.common;
		},
	},
	methods: {
		rotateArrow() {
			this.activeSelect = !this.activeSelect;
		},
		onSelect() {
			this.activeSelect = !this.activeSelect;
			this.$emit('onSelect');
		},
		onLang(lang) {
			Cookies.set('lang', lang.code.toUpperCase(), {expires: 7, domain: "shu.eu.com"});

			if (lang.code === this.currentLangCode) return;
			window.location.href = lang.host + this.$route.path;
		},
	}
}
</script>

<style lang="scss">
.select {
	&__lang {
		position: relative;
		z-index: 10;
		cursor: pointer;

		&-current {
			position: relative;
			z-index: 1;

			&:hover {
				border-bottom-color: transparent !important;
			}
		}

		&-selector {
			display: none;
			padding-top: 10px;
		}

		&-items {
			padding: 10px;
			background-color: $bg;
			border-top: 1px solid $black;
			display: flex;
			flex-direction: column;
			align-items: center;

			span {
				text-align: right;
				cursor: pointer;
				color: $black;
				@include transition();

				&.active {
					//color: $default-text-color;
				}

				&:hover {
					opacity: 0.6;
				}
			}
		}

		&:hover {
			.select__lang-selector {
				display: block;
			}
		}
	}

}
</style>