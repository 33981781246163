<template>
    <section class="follow text-center">

        <v-container>
            <h2> {{ texts.follow }} </h2>
            <ul
                class="flex-row-reverse align-center flex-wrap">
                <li v-for="(item, index) of items" :key="index" class="ml-3 mr-3">
                    <a :href="item.link" class="d-flex">
                        <img :src="item.img"/>
                    </a>
                </li>
            </ul>
            <!--<div v-else>
                <div class="d-md-none">
                    <div id="instawall-root48472" class="instawall instawall-root48472"></div>
                    <div class="tabs-inofd"><a href="https://contentuniq.com/">contentuniq</a>.</div></div>
                <div class="d-none d-md-block">
                    <div id="instawall-root82121" class="instawall instawall-root82121"></div>
                    <div class="tabs-mside"><a href="https://contentuniq.com/">contentuniq</a>.</div></div>
            </div>-->
        </v-container>

    </section>
</template>

<style lang="scss">
.follow {
    /*
    .instawall-root48472 {
        min-height: 80px;
        margin: 0 auto;
    }
    .tabs-inofd {
        display: none;
    }

    .instawall-root82121 {
        min-height: 80px;
        margin: 0 auto;
    }

    .tabs-mside {
        display: none;
    }
    */


    ul {
        display: flex;
        justify-content: center;
        margin-top: -17px;

        li {
            margin-bottom: 25px;
            @include up($sm) {
                margin-bottom: 0;
            }

            img {
                height: 240px;
                @include up($md) {
                    height: 265px;
                    width: auto;
                }

                @include up($lg) {
                    display: block;
                    height: 345px;
                    width: 100%;
                    max-width: 368px;
                }
            }

            a {
                display: block;
                position: relative;

                &:hover {
                    &:before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.29);
                        //filter: brightness(70%);
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        width: 40px;
                        height: 40px;
                        background-image: url("~@/assets/like.svg");
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}
</style>

<script>
export default {
    data() {
        return {
            items: [
                {
                    img: require('@/assets/socials/1.jpg'),
                },
                {
                    img: require('@/assets/socials/2.jpg'),
                },
                {
                    img: require('@/assets/socials/3.jpg'),
                },
                {
                    img: require('@/assets/socials/4.jpg'),
                },
            ]
        }
    },
    computed: {
        texts() {
            return {
                follow: this.$store.state.common?.commontext?.FOLLOW,
            }
        },
        languageCode() {
            return this.$store.state.common.languageCode;
        },
    },
    /*methods: {
        initWidgetXS() {
            window['instawall48472'] = {
                "token": "EeDe/27Yy5+a7YxzSRn3YX8JFaU81C3ibdv1Ye4P4b7yPIQcAF15NZZVHsPXtQls0HaVpeauhcYxNfJQQNYNIqZ98eRmzYijO40YUpITcCk7m0pBeo8/WRtY2oJAayCKZOdrdA==",
                "root_tagid": "instawall-root48472",
                "card_padding": "5",
                "card_count": "2",
                "layout_max_width": "1128",
                "count_post": "4",
                "header_text_size": "20",
                "header_text_color": "#000000",
                "header_logo_size": "50",
                "header_button_text": "Подписаться",
                "header_button_text_size": "12",
                "header_button_text_color": "#ffffff",
                "header_button_background_color": "#0095f6",
                "hide_groupname": "1",
                "hide_text": "0",
                "hide_shadow": "0",
                "show_header": "0",
                "show_header_logo": "1",
                "show_header_button": "1"
            };
            (function (d, s, id) {
                var js, dfjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.setAttribute("data-scriptid", "48472");
                js.src = "https://socactive.com/widgets/instawall/i.js?id=48472&r=" + Math.random();
                dfjs.parentNode.insertBefore(js, dfjs);
            }(document, "script", "instawall-export48472"));

        },
        initWidgetLG() {
            window['instawall82121'] = {
                "token": "o+rrHOV5Uikt376LGcC8foP3kqQpc+ZRSbGfYSewCDPuZWedvDLgaExaNYvyABYQc13lk/B03unUP4OYGYenL/j000N7Cf8yPxfmkhFKW6hD0PCbUm2EQB/b7+BQXJyCzEIQ/A==",
                "root_tagid": "instawall-root82121",
                "card_padding": "5",
                "card_count": "4",
                "layout_max_width": "1128",
                "count_post": "4",
                "header_text_size": "20",
                "header_text_color": "#000000",
                "header_logo_size": "50",
                "header_button_text": "Подписаться",
                "header_button_text_size": "12",
                "header_button_text_color": "#ffffff",
                "header_button_background_color": "#0095f6",
                "hide_groupname": "1",
                "hide_text": "0",
                "hide_shadow": "0",
                "show_header": "0",
                "show_header_logo": "1",
                "show_header_button": "1"
            };
            (function (d, s, id) {
                var js, dfjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.setAttribute("data-scriptid", "82121");
                js.src = "https://socactive.com/widgets/instawall/i.js?id=82121&r=" + Math.random();
                dfjs.parentNode.insertBefore(js, dfjs);
            }(document, "script", "instawall-export82121"));
        }
    },
    mounted() {
        this.initWidgetLG();
        this.initWidgetXS();
    }*/
}
</script>