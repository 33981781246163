export default {
    API_DEFAULT_ACTION: '/api/Form',
    SUBMIT_URL: '/api/SubmitController',
    SUBMIT_URL2: '/api/Submit2Controller',

    STORAGE_AUTH_TOKEN: "auth",
    STORAGE_PROP_ORDER_HASH: "orderHash",
    STORAGE_PROP_USER_ID: "userId",

    ERROR_CODE_FORBIDDEN: 403,
    API_EXCEPTION_NOT_FOUND: 'NOT_FOUND',
    API_EXCEPTION_FORBIDDEN: 'FORBIDDEN',
    API_EXCEPTION_EXISTS :'EXISTS',
    ERROR_CODE_WRONG_PARAMS: "WRONG_PARAMS",

    //in _variables.scss, config.js and plugins/vuetify.js
    WIDTH_SM: 1280,
    WIDTH_MD: 1440,
    WIDTH_LG: 1728 - 16,
    WIDTH_XL: 2560 - 16,

    MAPBOX: {
        accessToken: "pk.eyJ1IjoicGxheW5leHQiLCJhIjoiY2w0NGdkbWhrMDMzdzNkbzJudGU3aHIyZCJ9.aCdgbN39Uloe8_MjL2y1Xw",
        style: "mapbox://styles/playnext/cjqr7cp2i36ip2rqilaiuxmq3",
    },

    DEBOUNCE_INPUT_TIMEOUT: 800,

    LANGUAGES: [
        {
            name:"rus",
            code:"ru",
            host: "https://shu.eu.com",
        },
        {
            name:"eng",
            code:"en",
            host: "https://en.shu.eu.com",
        }
    ],

    LANGUAGES_MOBILE: [
        {
            name:"Russian language",
            code:"ru",
            host: "https://shu.eu.com",
        },
        {
            name:"English language",
            code:"en",
            host: "https://en.shu.eu.com",
        },
    ]
}