<template>
    <div class="product d-inline-block mb-6 mb-lg-10 mr-2 ml-2 mr-lg-7" v-if="item">

        <div class="">
            <div :class="item.IS_NEW ? 'product__main-image--before' : ''" class="product__main-image">

                <router-link :to="{name:'ProductPage', params:{code:item.CODE}}"
                             class="product__link mb-8">
                    <transition name="fade" mode="out-in">
                        <v-img v-if="imageUrl && selectedPhotoIndex === 0" :src="imageUrl" :alt="item.NAME" key="i1"/>
                        <v-img v-else-if="imageUrl2 && selectedPhotoIndex === 1" :src="imageUrl2" :alt="item.NAME"
                               key="i2"/>
                    </transition>
                    <!--          <div class="product__link-img-over" @mouseover="onImageOver"
                                   @mouseout="onImageOut"/>-->
                </router-link>

            </div>

            <div class="product__bg pa-2 pa-lg-4 pt-lg-5 pb-3 d-flex flex-column justify-start">
                <div class="product__info size-2 color-blue px-1 mb-2 mb-lg-4 text-left">
                    <router-link :to="{name:'ProductPage', params:{code:item.CODE}}" class="mb-4">
                        <h3 class="d-inline  size-2 color-blue"> {{ item.NAME }} </h3>
                    </router-link>
                    <!--<span> {{ item.description }} <br> {{ item.description2 }} </span>-->
                </div>

                <div v-if="item.offers && item.offers.length" class="product__colors d-inline-block">
                    <div class="d-flex align-center flex-wrap">
                        <button v-for="(offer, index) of displayedOffers" :key="index" :title="offer.COLOR"
                                @click="onOfferClick(offer, index)"
                                class="product__color"
                        >
                            <div :style="hexStyle(offer)"/>
                        </button>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
export default {
    name: 'product',
    props: {
        item: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            selectedOfferIndex: 0,
            selectedPhotoIndex: 0
        }
    },

    computed: {
        displayedOffers() {
            return this.item.offers?.slice(0, 4);
        },

        imageUrl() {
            if (!this.item?.offers?.length) return;

            const offer = this.item.offers[this.selectedOfferIndex];
            if (!offer.PHOTOS?.length) return;

            const imageUrl = offer.PHOTOS[0];
            return imageUrl;
        },
        imageUrl2() {
            if (!this.item?.offers?.length) return;

            const offer = this.item.offers[this.selectedOfferIndex];
            if (!offer.PHOTOS?.length) return;

            const imageUrl = offer.PHOTOS[1] ? offer.PHOTOS[1] : null;
            return imageUrl;
        },
        texts() {
            return this.$store.state.page?.ProductItemController?.texts;
        }
    },
    methods: {
        hexStyle(offer) {
            return {
                "background-color": "#" + offer.COLOR_HEX
            };
        },
        onOfferClick(offer, index) {
            this.selectedOfferIndex = index;
        },
        onImageOver() {
            const offer = this.item.offers[this.selectedOfferIndex];
            if (offer.PHOTOS.length < 2) return;
            this.selectedPhotoIndex = 1;
        },
        onImageOut() {
            this.selectedPhotoIndex = 0;
        }
    }

}
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .15s
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.product {
    max-width: 278px;
    background-color: $white;

    @include up($lg) {
        max-width: 345px;
    }

    &__bg {
        background-color: $white;
    }

    &__badge {
        position: absolute;
        transform: rotate(315deg);
        top: 25px;
        left: 5px;
        text-transform: uppercase;
    }

    /*  &__main-image {
        //width: 296px;
        position: relative;

        &--before {
          &:before {
            position: absolute;
            content: "";
            background-image: url("~@/assets/badge.svg");
            width: 93.34px;
            height: 93.34px;
            left: 0;
            top: 0;
          }
        }

      }*/

    h2 {
        font-size: 30px;
        line-height: 35px;
        color: $black500;
    }

    &__link {
        background-color: $white;
        position: relative;

        .v-image {
            width: 305px;
            height: 256px;
            @include transition();
            @include up($lg) {
                width: 369px;
                height: 312px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        /*    &-img-over {
              position: absolute;
              z-index: 1;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }*/


        .v-responsive__content {
            width: 100% !important;
        }
    }

    &__colors {
        //width: 208px;
        //height: 64px;
        /*    background-color: $additional150;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.06);
            border-radius: 1000px;
            box-sizing: border-box;*/
    }

    &__main-image {
        padding: 10px 10px 0;
    }

    &__color {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        border: 2px solid transparent;
        transition: all 0.3s ease-in;


        &:hover,
        &:focus,
        &:active {
            border: 2px solid #E53935;
        }

        & div {
            width: 26px;
            min-width: 26px;
            height: 26px;
            border-radius: 50%;
        }

    }

    .active {
        border: 2px solid #E53935;
    }

    &__info {
        height: 58px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        h3 {
            @include transition();

            &:hover {
                opacity: 0.7;
            }
        }

        span {
            display: block;
        }
    }
}
</style>