<template class="Home">
  <div class="home" id="home">
    <v-img class="d-sm-none stripe" width="100%" src="~@/assets/home/SHU-runner2.jpg"/>
    <section class="home__main">

      <div class="home__content">
        <img class="home__logo d-sm-none" contain src="~@/assets/logo-lg.svg"/>
        <v-img class="home__logo d-none d-sm-block" contain src="~@/assets/logo-lg.svg"/>
        <h1 v-html="texts.mainH1" class="mb-14"></h1>
      </div>
      <v-img class="home__banner d-md-none" src="~@/assets/home/top-banner-xs.jpg"/>
      <div class="home__img-container">
        <v-img class="home__banner d-none d-md-block" src="~@/assets/home/top-banner.jpg"/>
      </div>

    </section>
    <section class="home__inspire">
      <v-container>
        <v-row class="px-md-13 px-lg-0">
          <v-col cols="12" sm="6" class="home__motto">
            <div v-html="texts.mainMotto" class="size-6"></div>
          </v-col>
          <v-col v-html="texts.mainText" cols="12" sm="6" class="home__text">

          </v-col>
        </v-row>
      </v-container>
    </section>

    <directions/>
    <insta-shu v-if="languageCode !== 'en'"/>
    <!--<section class="home__plague --1 size-7">
        <div v-html="texts.mainHead1">
        </div>
    </section>
    -->


    <popular-items/>
    <section class="home__plague --2 size-7">
      <v-container class="home__plague-container">
        <div v-html="texts.mainHead2">
        </div>
        <img class="d-block ma-auto d-md-none" src="~@/assets/home/girl-tattoo.png">
      </v-container>
    </section>
    <new-items id="new"/>

    <follow/>
  </div>
</template>

<style lang="scss">
.home {
  & .stripe {
    z-index: 2;
  }

  & .directions {
    padding: 40px 0 75px;
  }

  & .new-items {
    padding: 80px 0 45px;
    @include up($sm) {
      //padding: 155px 0 45px;
    }
  }

  & .popular-items {
    padding: 75px 0 30px;
    @include up($sm) {
      padding: 75px 0 80px;
    }
  }

  & .follow {
    padding: 95px 0 70px;
  }

  & .insta-shu {
    padding: 0;
  }


  &__main {
    background-image: url("~@/assets/home/1.jpg");
    background-size: cover;
    background-position: top left;
    height: 100vh;
    padding-top: 98px;
    position: relative;
    @include up($sm) {
      height: unset;
      padding-top: 180px;
    }
    @include up($md) {
      padding-top: 150px;
      background-image: url("~@/assets/home/2560_background.jpeg");
    }

    @include up($lg) {
      padding: 210px 0 0;
    }


    & img {
      margin: 0 auto;
    }
  }

  &__img-container {
    padding: 0 68px;
    @include up($xl) {
      padding: 0 130px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 2;
    position: relative;
    padding: 0 15px;
    @include up($sm) {
      padding: 0;
      margin-bottom: 30px;
    }
    @include up($md) {
      margin-bottom: 80px;
    }
  }

  &__logo {
    height: 55px;
    margin: 0 auto;
    @include up($sm) {
      height: unset;
    }
  }

  &__motto {
    @include up($lg) {
      padding-left: 75px !important;
    }
  }

  &__text {
    @include up($lg) {
      padding-right: 100px !important;
    }
  }

  &__banner {
    width: 100%;
    height: 310px;
    z-index: 3;
    position: relative;
    @include up($sm) {
      margin-top: 0;
      height: 390px;
    }
    @include up($lg) {
      height: 516px;
    }
  }


  &__inspire {
    display: flex;
    justify-content: center;
    padding-top: 400px;
    position: relative;
    @include up($sm) {
      padding-top: 50px;
    }
    @include up($lg) {
      padding-top: 90px;
    }

    &:before {
      @include up($md) {
        position: absolute;
        content: "";
        top: 140px;
        left: -490px;
        z-index: -1;
        opacity: 0.4;
        width: 880px;
        height: 1156px;
        background-image: url("~@/assets/home/tiger-tattoo.png");
      }
      @include up($xl) {
        left: -320px;
      }
    }

    & div {
      color: $black;
      margin-bottom: 10px;
      text-shadow: 0px 0px 5px rgba(169, 11, 29, 0.3);
    }


    & p {
      color: $black500 !important;

      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }


  &__plague {
    text-align: center;
    text-shadow: 0px 0px 5px rgba(169, 11, 29, 0.3);


    &.--1 {
      background-color: $black;
      color: $light;
      padding: 120px 0 110px;
      text-shadow: 0px 0px 5px rgba(169, 11, 29, 0.3);
      position: relative;
      @include up($xl) {
        margin: 0 130px;
      }

      &:before {
        position: absolute;
        content: "";
        border: 3px solid #F4ECE5;
        top: 0;
        left: 50%;
        width: 0;
        height: 72px;
        margin-right: -50%;
        @include up($sm) {
          width: 43px;
          height: 43px;
          top: 44%;
          left: 200px;
          border: none;
          margin-right: 0;
          transform: rotate(90deg);
          background-image: url("~@/assets/star-red.png");
        }
        @include up($md) {
          left: 255px;
        }
      }

      &:after {
        position: absolute;
        content: "";
        border: 3px solid #F4ECE5;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 72px;
        margin-right: -50%;
        @include up($sm) {
          position: absolute;
          content: "";
          width: 43px;
          height: 43px;
          top: 44%;
          right: 190px;
          border: none;
          margin-right: 0;
          left: unset;
          background-image: url("~@/assets/star-red.png");
        }
      }

      & div {
        position: relative;

        &:before {
          @include up($sm) {
            position: absolute;
            content: "";
            top: -84px;
            border: 3px solid $light;
            width: 1px;
            height: 72px;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &:after {
          @include up($sm) {
            position: absolute;
            content: "";
            bottom: -147px;
            border: 3px solid $light;
            width: 1px;
            height: 72px;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &.--2 {
      color: $black;
      position: relative;
      z-index: 0;
      @include up($md) {
        padding: 135px 0;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        content: "";
        width: 100%;
        height: 100%;
        background: radial-gradient(50% 50% at 50% 50%, #EDDDB7 38.54%, rgba(237, 221, 183, 0) 100%);
      }

    }
  }

  &__plague-container {
    position: relative;

    &:before {
      @include up($md) {
        position: absolute;
        top: -105px;
        left: -65px;
        z-index: -1;
        content: "";
        width: 417px;
        height: 402px;
        background-image: url("~@/assets/home/girl-tattoo.png");
        background-size: contain;
      }
      @include up($lg) {
        left: 45px;
      }
    }
  }
}
</style>


<script>

import TheHeader from "../layout/main/the-header";
import TheFooter from "../layout/main/the-footer";
import MainBanner from "./main-banner";
import Directions from "./directions";
import PopularItems from "./popular-items";
import NewItems from "./new-items";
import Product from "../components/product";
import InstaShu from "./insta-shu";
import Follow from "./follow";

export default {
  name: 'Home',
  components: {
    Follow,
    InstaShu,
    Product,
    NewItems,
    PopularItems,
    MainBanner,
    TheFooter,
    TheHeader,
    Directions,
  },
  computed: {
    languageCode() {
      return this.$store.state.common.languageCode;
    },
    popularProducts() {
      return this.$store.state.page?.HomeController?.popularProducts;
    },
    mainBanner() {
      /*title="Коллекция Urban осень-зима 2021"
      text="Ослепительный хайлайтер в невесомой бархатистой текстуре"
      btn="Подробнее"*/
      if (!this.$store.state.page?.HomeController?.banners) return null;
      return this.$store.state.page?.HomeController?.banners[0];

    },
    secondBanner() {

      /* title="Создай образ"
       text="Открой для себя актуальные образы и самые главные тренды в макияже вместе с косметикой SHU"
       btn="Подробнее"*/
      if (!this.$store.state.page?.HomeController?.banners) return null;
      if (this.$store.state.page?.HomeController?.banners.length < 2) return null;
      return this.$store.state.page?.HomeController?.banners[1];
    },
    thirdBanner() {

      /* title="Создай образ"
       text="Открой для себя актуальные образы и самые главные тренды в макияже вместе с косметикой SHU"
       btn="Подробнее"*/
      if (!this.$store.state.page?.HomeController?.banners) return null;
      if (this.$store.state.page?.HomeController?.banners.length < 3) return null;
      return this.$store.state.page?.HomeController?.banners[2];
    },

    texts() {
      return {
        mainH1: this.$store.state.page.HomeController.texts?.MAIN_H1,
        mainMotto: this.$store.state.page.HomeController.texts?.MAIN_MOTTO,
        mainText: this.$store.state.page.HomeController.texts?.MAIN_TEXT,
        mainHead1: this.$store.state.page.HomeController.texts?.MAIN_HEAD1,
        mainHead2: this.$store.state.page.HomeController.texts?.MAIN_HEAD2,
      }
    },


  },
  mounted() {
    console.log("New products: ", this.$store.state.page?.HomeController?.newProducts);
    console.log("Pop products: ", this.$store.state.page?.HomeController?.popularProducts);
  },
}
</script>